@use '../../scss/' as *;

.tf-step {
    background: var(--primary-color8);
    padding: 76px 30px 30px 30px;
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0px 10px 25px rgba(71, 92, 133, 0.1);
    position: relative;
    @include transition3;
    & .step-title {
        position: relative;
        margin-bottom: 18px;
        & .sub-number {
            position: absolute;
            top: -13px;
            left: 50%;
            transform: translateX(-50%);
            font-family: "Poppins";
            font-weight: 600;
            font-size: 80px;
            color: #f84364;
            opacity: 0.3;
            @include transition3;
        }
        & h3 {
            position: relative;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 24px;
            text-transform: uppercase;
            color: var(--primary-color3);
        }
    }
    & p {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color);
    }
    &.style2 {
        text-align: left;
        & .step-title {
            position: relative;
            margin-bottom: 18px;
            & .sub-number {
                position: absolute;
                top: -13px;
                left: 40px;
                font-family: "Poppins";
                font-weight: 600;
                font-size: 80px;
                opacity: 0.3;
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #f84364;
                @include transition3;
            }
            & h3 {
                position: relative;
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 24px;
                text-transform: uppercase;
                color: var(--primary-color3);
            }
        }
    }
    &:hover {
        box-shadow: 0px 10px 25px rgba(71, 92, 133, 0.1);
        cursor: pointer;
        .step-title {
            .sub-number {
                color: #f84364;
                opacity: 0.8;
            }
        }
    }
    &::before {
        opacity: 0;
        @include transition5;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0px;
        left: 0px;
        border-left: 5px solid #f84364;   
        border-top: 5px solid #f84364;
    }
    &::after {
        opacity: 0;
        @include transition5;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0px;
        right: 0px;
        border-right: 5px solid #f84364;
        border-bottom: 5px solid #f84364;
    }
    &.active,
    &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 15px var(--primary-color36);
        &::before,&::after {
            opacity: 1;
            width: 50px;
            height: 50px;
        }
    }
}
.is_dark .tf-step {
    box-shadow: none;
    &.active,
    &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 15px var(--primary-color36);
        &::before,&::after {
            opacity: 1;
        }
    }
}