@use '../../scss/' as *;
.tf-container {
    // margin-bottom: 50px;

    .title {
        margin-bottom: 20px;
        line-height: 1rem;
    }

    .pdf-container {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        overflow: auto;
        background-color: black;
        max-width: 80%;
        height: 80vh; // Adjust this value as needed

        .pdf-viewer {
            width: 100%;

            .react-pdf__Page {
                display: block;
                margin: 0 auto;

                canvas {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
