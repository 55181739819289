@use '../../scss/' as *;


.custom-blog-item-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.custom-blog-article {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 0 20rem;
  padding: 12rem 35rem; // More horizontal padding

  &:hover {
    transform: translateY(-5px);
  }

  &:hover .image-overlay {
    opacity: 0.8;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); // Dark overlay
    border-radius: 8px;
    z-index: 1;
    opacity: 0.3; // Initial opacity
    transition: opacity 0.3s ease;
  }

  @media (max-width: 768px) {
    margin: 0 1rem;
    padding: 20px; // Adjusted padding for mobile
  }
}

.custom-content {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
}

.custom-text-content {
  flex: 1;
  max-width: 100%;
  font-size: 1rem; // Increased font size
  color: white; // Text color
  text-align: center; // Center text


}

.custom-title {
  margin-bottom: 15px;
  color: white;

  @media (max-width: 768px) {
    font-size: 2.1rem;
    padding: 0 2rem; // Adjusted font size for mobile
  }

  // Increased margin
}

.custom-meta {
  display: flex;
  justify-content: space-between;
}

.custom-btn-readmore {
  align-self: flex-start;
  margin-top: 6rem;
}

.tf-container {
  .heading {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;

     @media (max-width: 768px) {
        font-size: 2.6rem;
         font-weight: 600;
    }

    @media (max-width: 576px) {
        font-size: 2rem;
         font-weight: 600;
    }
  }

  .row.justify-content-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; // Adjust gap between items
  }

  .tf-product {
    .image-wrapper {
      position: relative;
      width: 100%;
      text-align: center;


      .textSizeBox {
        width: 15rem; 
        // max-height: 200px;
        justify-content: center;
        align-items: center;
        height: 12.5rem;
        // font-weight: 500;
        // background-color: aqua;
        text-align: center;
        line-height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -65%);
      }

      .image {
        position: relative;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: grey;
          max-width: 150px; // Set to the width of the containe
          font-weight: 500;
          text-align: center;
          line-height: 1.5rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-53%, -50%);
        }
      }

    }

    .name {
      text-align: center;
    }
  }
}