@use '../../scss/' as *;
.tf-page-title {
    padding: 77px 0;
    text-align: center;
    // background: rgba(255, 255, 255, 0.085) center center no-repeat fixed;
    // background-size: cover;
    position: relative;

    &::before {
        content: '';
        position: relative;
        // left: 0;
        // top: 0;
        justify-self: center;
        width: 100%;
        height: 100%;
        text-align: center;

        // background-color: rgba(20, 20, 20, 0.308);

    }
    &.style-2 {
        padding: 24px 0 15px;
    }
    .breadcrumbs {
        width: 100%;
        @include flex(center,center);
        li {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: inline-block;
            padding: 0 10px 0 0px;
            color: var(--primary-color5);
            text-transform: uppercase;
            &:last-child {
                padding-right: 0;
            }
            a {
                font-weight: 700;
                position: relative;
                color: #888B8E;
                padding-right: 11px;
                
                &::after {
                    content: "";
                    position: absolute;
                    color: #888B8E;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 2px;
                    height: 10px;
                    background-color:#888B8E ;
                }
                &:hover {
                    color: var(--primary-color5);
                }
            }
            
        }
    }
    .page-title-heading {
        color: #fff ;
        margin-bottom: 12px;
        font-weight: 500;
    }
    &.style-2 {
        .breadcrumbs {
            margin-bottom: 0;
        }
    }
}
