@use '../../scss/' as *;

.blogTitle {
    font-size: 2.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        font-size: 3rem;
        
    }

    @media (min-width: 1200px) { // Extra large devices (large desktops, 1200px and up)
        font-size: 3.2rem;
    }
}
